import { cn } from 'class-merge';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import useIsMobile from '~libs/use-is-mobile';
import { S3_BUCKET_IMAGES } from '~constants/etc';

const OutstandingFeatures = () => {
  const { t } = useTranslation('home');
  const isMobile = useIsMobile();

  const data = [
    {
      title: t('outstanding-features.high-quality'),
      description: t('outstanding-features.description-high-quality'),
      image: `${S3_BUCKET_IMAGES}/home-high-quality.webp`,
    },
    {
      title: t('outstanding-features.product-variety'),
      description: t('outstanding-features.description-product-variety'),
      image: `${S3_BUCKET_IMAGES}/home-product-variety.webp`,
    },
    {
      title: t('outstanding-features.safety-privacy'),
      description: t('outstanding-features.description-safety-privacy'),
      image: `${S3_BUCKET_IMAGES}/home-safety-privacy.webp`,
    },
    {
      title: t('outstanding-features.deposit-withdraw-quickly'),
      description: t(
        'outstanding-features.description-deposit-withdraw-quickly',
      ),
      image: `${S3_BUCKET_IMAGES}/home-deposit-withdraw-quickly.webp`,
    },
  ];

  if (isMobile) return null;

  return (
    <div
      className={cn(
        'mx-auto max-w-[2000px] space-y-5 px-10 py-10 xl:px-[100px]',
      )}
    >
      <h2 className="text-primary text-center text-4xl font-bold">
        {t('outstanding-features.title')}
      </h2>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4">
        {data.map((item, i) => (
          <div
            className="xl:border-primary flex items-center gap-x-2.5 border-r-2 border-transparent px-5 py-4 last:border-none"
            key={i}
          >
            <Image
              alt={item.title}
              draggable={false}
              height={72}
              src={item.image}
              width={72}
            />
            <div className="flex flex-col">
              <h3 className="text-sm font-bold">{item.title}</h3>
              <p className="text-xs">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OutstandingFeatures;
