import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import type { FC } from 'react';
import { S3_BUCKET_IMAGES } from '~constants/etc';

const CategoryContainer: FC = () => {
  const { t } = useTranslation();

  const products = [
    {
      key: 'casino',
      label: 'live casino',
    },
    {
      key: 'sport',
      label: 'sport',
    },
    {
      key: 'gaming',
      label: 'slot',
    },
  ];

  return (
    <div className="relative mb-20 mt-10 h-full w-full">
      <div
        className="h-[530px] w-full bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${S3_BUCKET_IMAGES}/products-bg.webp)`,
        }}
      >
        <div className="absolute left-1/2 top-0 h-[550px] w-[680px] -translate-x-1/2">
          <Image
            alt="product-model"
            draggable={false}
            fill
            src={`${S3_BUCKET_IMAGES}/product-model.webp`}
          />
        </div>
      </div>
      <div className="to-default absolute -bottom-10 left-1/2 h-[400px] w-full -translate-x-1/2 bg-gradient-to-b from-transparent px-10 text-center xl:px-[100px]">
        <div className="mt-32">
          <h2 className="text-center text-4xl font-bold">
            {t('home:products.title')}
          </h2>
          <div className="pt-5">
            <div className="grid h-[270px] grid-cols-4 gap-6">
              {products.map((item) => (
                <div
                  className="bg-dark border-primary relative overflow-hidden rounded-2xl border-2 bg-cover bg-no-repeat p-6 first:col-span-2"
                  key={item.key}
                  style={{
                    backgroundImage: `url(${S3_BUCKET_IMAGES}/procuct-${item.key}-bg.webp)`,
                  }}
                >
                  <p className="relative z-10 max-w-[160px] text-left text-4xl font-bold uppercase">
                    {item.label}
                  </p>
                  <div className="absolute right-0 top-0 z-0 h-full w-[280px]">
                    <Image
                      alt={`${item.key}model`}
                      draggable={false}
                      fill
                      src={`${S3_BUCKET_IMAGES}/procuct-${item.key}-model.webp`}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryContainer;
