import { cn } from 'class-merge';
import Image from 'next/image';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '~components/button';
import VideoPlayer from '~components/video-player';
import { ButtonColors } from '~constants/components';
import useAuth from '~hooks/use-auth';
import { useRouter } from '~hooks/use-router';
import useIsMobile from '~libs/use-is-mobile';
import { S3_BUCKET_IMAGES } from '~constants/etc';

const Presentation: FC = () => {
  const router = useRouter();
  const isMobile = useIsMobile();
  const { isLoggedIn } = useAuth();
  const { t } = useTranslation('common');
  const handleAction = (action: string) => {
    const currentUrl = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.set('action', action);
    router.push(`${currentUrl}?${params.toString()}`, { scroll: false });
  };
  if (isMobile) return null;
  return (
    <div
      className={cn(
        'h-full w-full bg-cover bg-center bg-no-repeat px-10 xl:h-[450px] xl:px-[100px]',
      )}
      style={{
        backgroundImage: `url(${S3_BUCKET_IMAGES}/home-video-bg.webp)`,
      }}
    >
      <div className="flex items-center justify-center gap-x-10">
        <div className="relative hidden h-[300px] w-full max-w-[512px] xl:block xl:h-[450px]">
          <Image
            alt="home-video-main"
            draggable={false}
            fill
            src={`${S3_BUCKET_IMAGES}/home-video-main.webp`}
          />
          {isLoggedIn ? (
            <Button
              className="absolute bottom-0 left-20 xl:bottom-16"
              color={ButtonColors.Default}
              onClick={() => {
                handleAction('login');
              }}
            >
              {t('bet-now')}
            </Button>
          ) : null}
        </div>
        <VideoPlayer
          classNames="h-full w-full w-[550px] xl:w-[680px]"
          src="/videos/home-video.mp4"
        />
      </div>
    </div>
  );
};
export default Presentation;
