'use client';

import type { FC } from 'react';
import { useEffect } from 'react';
import { useSearchParams } from 'next/navigation';
import { setCookie } from 'nookies';
import Affiliate from '~containers/home/affiliate';
import CustomCarousel from '~containers/home/custom-carousel';
import OutstandingFeatures from '~containers/home/outstanding-features';
import Presentation from '~containers/home/presentation';
import Products from '~containers/home/products';
import type { AgentInfoResponseInterface } from '~libs/get-agent-info';

const HomeClientPage: FC<{
  agentInfo: AgentInfoResponseInterface;
  region: string;
}> = ({ agentInfo, region }) => {
  const searchParams = useSearchParams();
  const referralId = searchParams.get('ref') || '';
  const subAgentReferralCode = searchParams.get('sa') || '';

  useEffect(() => {
    if (referralId) {
      setCookie(undefined, 'referralId', referralId);
    }
    if (subAgentReferralCode) {
      setCookie(undefined, 'subAgentReferralCode', subAgentReferralCode);
    }
  }, [referralId, subAgentReferralCode]);

  return (
    <>
      <CustomCarousel />
      <OutstandingFeatures />
      <Presentation />
      <Products agentInfo={agentInfo} region={region} />
      <Affiliate />
    </>
  );
};

export default HomeClientPage;
