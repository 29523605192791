'use client';

import { cn } from 'class-merge';
import type { FC } from 'react';

interface VideoPlayerInterface {
  autoPlay?: boolean;
  classNames?: string;
  loop?: boolean;
  muted?: boolean;
  src: string;
}

const VideoPlayer: FC<VideoPlayerInterface> = ({
  autoPlay = true,
  classNames,
  loop = true,
  muted = true,
  src,
}) => {
  const classNameMemo = cn('object-cover', classNames);

  return (
    <video
      autoPlay={autoPlay}
      className={classNameMemo}
      loop={loop}
      muted={muted}
      playsInline
    >
      <source src={src} type="video/mp4" />
    </video>
  );
};

export default VideoPlayer;
