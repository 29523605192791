'use client';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { proxied } from 'radash';
import { parseCookies } from 'nookies';
import type { ProviderCasinoResApiInterface } from '../../types/casino';
import type { ProviderSportResApiInterface } from '../../types/sport';
import CategoryContainer from './category-container';
import PopularGames from './popular-game-container';
import { ProviderEnum } from '~constants/menus';
import useIsMobile from '~libs/use-is-mobile';
import 'swiper/css';
import 'swiper/css/navigation';
import { useCasinos } from '~hooks/use-casinos';
import { useSportsbook } from '~hooks/use-sports-book';
import type { AgentInfoResponseInterface } from '~libs/get-agent-info';
import { useAllGames } from '~hooks/use-gaming';
import { DEFAULT_CURRENCY, ProviderTypeEnum } from '~constants/etc';
import { onOpenSportURL } from '~libs/open-url';
import PageUrls from '~constants/page-urls';
import { useRouter } from '~hooks/use-router';
import { useUserInfo } from '~hooks/use-user';
import { useWalletInfo } from '~hooks/use-wallet';
import objectToQueryString from '~libs/object-to-query-string';
import { encrypt } from '~libs/encrypt';
import HttpErrorHandler from '~components/http-error-handler';
import SportProviderContainer from '~containers/home/sport-provider-container';
import ProductMobileViewContainer from '~containers/home/product-mobile-view-container';
import AllProviderContainer from '~containers/home/all-provier-container';
import { ClientSideRender } from '~components/layouts/client-side-render';
import UnauthorizedAlertWidget from '~components/widgets/unauthorized-alert-widget';
import { useDialog } from '~hooks/use-dialog';

const Products: FC<{
  agentInfo: AgentInfoResponseInterface;
  region: string;
}> = ({ agentInfo, region }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [dialog, dialogContext] = useDialog();
  const { data: casinos } = useCasinos();
  const { data: gaming } = useAllGames();
  const { data: sports } = useSportsbook();
  const router = useRouter();
  const { data: userInfo } = useUserInfo();
  const { data: walletInfo } = useWalletInfo(
    userInfo?.selectedWallet.objectId || '',
  );
  const currency = walletInfo?.currency || DEFAULT_CURRENCY;
  const cookies = parseCookies();
  const locale = cookies.NEXT_LOCALE || '';

  const allProviders = [
    ...(casinos || []),
    ...(gaming || []),
    ...(sports || []),
  ];

  const providerData = {
    all: {
      key: 'all',
      name: t('common:all'),
      icon: '',
      data: allProviders,
    },
    [ProviderEnum.CASINO]: {
      key: ProviderEnum.CASINO,
      name: t('common:menu.casino'),
      icon: '/images/svg/flags/th.svg',
      data: casinos,
    },
    [ProviderEnum.GAMING]: {
      key: ProviderEnum.GAMING,
      name: t('common:menu.gaming'),
      icon: '/images/svg/flags/en.svg',
      data: gaming,
    },
    [ProviderEnum.SPORT]: {
      key: ProviderEnum.SPORT,
      name: t('common:menu.sportsbook'),
      icon: '/images/svg/flags/vi.svg',
      data: sports,
    },
  };

  const handleOpenCasino = (game: ProviderCasinoResApiInterface): void => {
    if (!userInfo) {
      return dialog.content({
        children: (
          <ClientSideRender>
            <UnauthorizedAlertWidget onClose={() => dialog.destroy()} />
          </ClientSideRender>
        ),
      });
    }
    try {
      const currencyFiltered = game.currencies
        .filter((x) => x.includes(currency))
        .at(0);
      const queryString = objectToQueryString({
        category: ProviderTypeEnum.Casino,
        providerId: game.id,
        currency: currencyFiltered,
        walletId: walletInfo?.objectId,
      });
      router.push(`/${locale}${PageUrls.OpenGame}?qs=${encrypt(queryString)}`);
    } catch (e) {
      HttpErrorHandler(e);
    }
  };

  const handleOpenSportsbook = async (
    game: ProviderSportResApiInterface,
  ): Promise<void> => {
    if (!userInfo) {
      return dialog.content({
        children: (
          <ClientSideRender>
            <UnauthorizedAlertWidget onClose={() => dialog.destroy()} />
          </ClientSideRender>
        ),
      });
    }
    try {
      const currencyFiltered = game.currencies
        .filter((x) => x.includes(currency))
        .at(0);
      window.open(
        await onOpenSportURL(game.id, currencyFiltered, walletInfo?.objectId),
        '_blank',
      );
    } catch (e) {
      HttpErrorHandler(e);
    }
  };

  const actionOpenGame = proxied((prop: ProviderTypeEnum) => {
    switch (prop) {
      case ProviderTypeEnum.Casino:
        return (provider: any) => {
          handleOpenCasino(provider);
        };
      case ProviderTypeEnum.Gaming:
        return (provider: any) =>
          router.push(`${PageUrls.Gaming}/${provider.id}`);
      case ProviderTypeEnum.Sportsbook:
        return (provider: any) => handleOpenSportsbook(provider);
      default:
        throw new Error('The category you are looking for cannot be found.');
    }
  });

  if (isMobile) {
    return (
      <ProductMobileViewContainer
        agentInfo={agentInfo}
        onSelectProvider={(provider) => {
          actionOpenGame[provider.meta.category](provider);
        }}
        providerData={providerData}
        region={region}
      />
    );
  }

  return (
    <div className="mx-auto w-full max-w-[2000px]">
      {dialogContext}
      <CategoryContainer />
      <SportProviderContainer
        onOpenProvider={(provider) => handleOpenSportsbook(provider)}
        sportProviders={sports}
      />
      <AllProviderContainer
        onSelectProvider={(provider) => {
          actionOpenGame[provider.meta.category](provider);
        }}
        providers={allProviders}
      />
      <PopularGames />
    </div>
  );
};

export default Products;
