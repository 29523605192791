'use client';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';
import type { FC } from 'react';
import { S3_BUCKET_IMAGES } from '~constants/etc';

const CustomCarousel: FC = () => {
  return (
    <Swiper
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      className="relative z-0 h-full w-full"
      loop
      modules={[Pagination, Autoplay]}
      pagination={{ clickable: true }}
      slidesPerView={1}
      spaceBetween={0}
    >
      {Array.from({ length: 6 }).map((_, i) => {
        return (
          <SwiperSlide className="h-full w-full" key={i}>
            <div
              className="relative h-full min-h-[130px] w-full bg-contain bg-left bg-no-repeat md:min-h-[240px] md:bg-cover lg:min-h-[340px] 2xl:min-h-[500px] 2xl:bg-bottom"
              style={{
                backgroundImage: `url(${S3_BUCKET_IMAGES}/carousel-${i + 1}.webp)`,
              }}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default CustomCarousel;
